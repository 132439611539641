// Warframe.com - Warframe 1999 Global Navigation Overrides
// Use in conjunction with logo overrides and dark mode

@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";

$COLOR_1999_HUB_BACKGROUND_TOP: #030404;
$COLOR_1999_HUB_BACKGROUND_BOTTOM: #263A3C;

$FONT_FAMILY_1999_NAVIGATION: "Courier Prime", "Courier New", monospace;
$COLOR_1999_NAVIGATION_BACKGROUND: rgba($COLOR_BLACK, 0.92);
$COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN: $COLOR_BLACK;
$COLOR_1999_NAVIGATION_TEXT: #B6F1E4;
$COLOR_1999_NAVIGATION_ACTIVE: #5AB19E;
$COLOR_1999_NAVIGATION_BORDER: #24533D;
$FILTER_1999_NAVIGATION_TEXT: invert(90%) sepia(25%) saturate(300%) hue-rotate(109deg) brightness(96%) contrast(98%);

// Header overrides
#header {
    .HeaderNavigationBar {
        --color-navigation-text: #{$COLOR_1999_NAVIGATION_TEXT};
        --color-navigation-text-hover: #{$COLOR_BLACK};
        --color-navigation-text-dropdown: #{$COLOR_WHITE};
        --color-navigation-background: #{$COLOR_1999_NAVIGATION_BACKGROUND};
        --color-navigation-background-dropdown: #{$COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN};
        --color-navigation-background-hover: #{$COLOR_1999_NAVIGATION_ACTIVE};
        --color-navigation-border: #{$COLOR_1999_NAVIGATION_BORDER};
    }

    .HeaderNewLabel-tag {
        background-color: $COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN;
        color: $COLOR_WHITE;
    }

    .HeaderMenuItem-label {
        font-family: $FONT_FAMILY_1999_NAVIGATION;
    }

    .HeaderMenuItem-option {
        font-family: $FONT_FAMILY_1999_NAVIGATION;
        //--color-link: #{$COLOR_1999_NAVIGATION_TEXT};
        //--color-link-hover: #{$COLOR_1999_NAVIGATION_TEXT};
        &:hover {
            .Icon {
                filter: none;
            }
        }
    }

    .HeaderMenuItem-icon,
    .HeaderMenuItem-linkIcon {
        filter: $FILTER_1999_NAVIGATION_TEXT;
    }

    .HeaderUserProfile,
    .HeaderUserMenu {
        font-family: $FONT_FAMILY_1999_NAVIGATION;
    }

    .Button {
        font-family: $FONT_FAMILY_1999_NAVIGATION;
        border-radius: 0;
        line-height: 1;
    }

    .Button--primary {
        --color-button-text: #{$COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN};
        --color-button-text-hover: #{$COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN};
        --color-button-background: #{$COLOR_1999_NAVIGATION_TEXT};
        --color-button-background-hover: #{$COLOR_WHITE};
        --color-button-border: #{$COLOR_1999_NAVIGATION_TEXT};
        --color-button-border-hover: #{$COLOR_WHITE};
    }

    .Button--secondary {
        --color-button-text: #{$COLOR_1999_NAVIGATION_TEXT};
        --color-button-text-hover: #{$COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN};
        --color-button-background: #{$COLOR_1999_NAVIGATION_BACKGROUND_DROPDOWN};
        --color-button-background-hover: #{$COLOR_1999_NAVIGATION_TEXT};
        --color-button-border: #{$COLOR_1999_NAVIGATION_TEXT};
        --color-button-border-hover: #{$COLOR_1999_NAVIGATION_TEXT};
        --filter-button-icon: #{$FILTER_1999_NAVIGATION_TEXT};
        --filter-button-icon-hover: none;
    }

    .HeaderNavigationBar-mobileMenuIcon {
        filter: $FILTER_1999_NAVIGATION_TEXT;
    }

    .HeaderDesktopMenu {
        .HeaderMenuItem {
            &-option {
                --color-link: #{$COLOR_1999_NAVIGATION_TEXT};
                --color-link-hover: #{$COLOR_1999_NAVIGATION_TEXT};
            }
        }
    }

    .HeaderMobileMenu {
        --color-navigation-text-dropdown: #191919;
        --filter-navigation-icon-dropdown: none;

        .HeaderMenuItem {
            &-option {
                --color-link: #{$COLOR_BLACK};
                --color-link-hover: #{$COLOR_BLACK};
            }
        }
    }
}

// Footer overrides
$COLOR_FOOTER_BACKGROUND: #010806;
$COLOR_FOOTER_TEXT: $COLOR_1999_NAVIGATION_TEXT;
$COLOR_FOOTER_BORDER: $COLOR_1999_NAVIGATION_BORDER;
$FILTER_FOOTER_TEXT: $FILTER_1999_NAVIGATION_TEXT;
#footer {
    .FooterNavigationContainer {
        background-color: $COLOR_FOOTER_BACKGROUND;
    }
    .FooterSocialLinks {
        &-title,
        &-divider {
            display: none;
        }
    }
    .FooterSocialLink {
        background-color: transparent;
        border: solid 1px $COLOR_FOOTER_BORDER;

        .Icon {
            filter: $FILTER_FOOTER_TEXT;
        }
    }
    .FooterNavigationLink,
    .FooterNavigationLanguageDropdown-label,
    .FooterNavigationLanguageDropdown-option {
        font-family: $FONT_FAMILY_1999_NAVIGATION;
        color: $COLOR_FOOTER_TEXT;
    }
    .FooterNavigationLinkDivider {
        display: none;
    }
    .FooterLogo {
        margin: 0;
    }
    .FooterPlatformIcons,
    .FooterNavigationLanguageDropdown-languageIcon {
        filter: $FILTER_FOOTER_TEXT;
    }
    .FooterNavigationBar-content {
        border-top: solid 1px $COLOR_FOOTER_BORDER;
        padding-top: $SPACE_MEDIUM;
        border-bottom: solid 1px $COLOR_FOOTER_BORDER;
        padding-bottom: $SPACE_MEDIUM;
    }
    .FooterNavigationBar-inner {
        @include breakpoint-desktop-up {
            background-color: transparent;
            justify-content: space-between;
            padding: 0;
        }
    }
    .FooterNavigationLanguageDropdown {
        &-overlay {
            border: solid 1px $COLOR_FOOTER_BORDER;

            @include breakpoint-desktop-up {
                border-color: transparent;
            }
        }
        &-dropdown {
            border: solid 1px $COLOR_FOOTER_BORDER;
            background-color: $COLOR_1999_HUB_BACKGROUND_TOP;
        }
    }
    .FooterCopyrightText,
    .FooterRatingBoardCard-text {
        color: $COLOR_FOOTER_TEXT;
    }
}
